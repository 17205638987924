import AppStore from "./AppStore";
import { buildClassName } from "./util";

// id: "b98a193a-0c9c-4dbc-a1c9-bb8cade3510d"
// title: "Members Area Header"
// type: :reference
// key: "members-area-header"
// parent_id: nil
export function MembersAreaHeaderB98a193a0c9c4dbcA1c9Bb8cade3510d(props: any) {
  return (
    <ShortHeaderB208e88eA1074e318c50Dcadc03155e2 parentTag="MembersAreaHeaderB98a193a0c9c4dbcA1c9Bb8cade3510d" header-top={<DefaultHeaderTop09bac254B4854ec3B6b35654aee06157 parentTag="HeaderTop7217c39101c347b1Babe8d180b35627f" navMenuSlug="members-primary-nav" />} {...props} />
  );
}

// id: "5428cbbf-9acf-44f9-b7b8-fb037b60d85b"
// title: "Resources Page"
// type: :reference
// key: "resources-page"
// parent_id: nil
export function ResourcesPage5428cbbf9acf44f9B7b8Fb037b60d85b(props: any) {
  return (
    <SecondaryPage9876ac9368a849929b915393e6e91d40 parentTag="ResourcesPage5428cbbf9acf44f9B7b8Fb037b60d85b" header={<SecondaryMarketingHeaderC9b608a9Cda54d6c8fa5Db800f84de9c parentTag="HeaderCbe1aef8E30c4987Afe29fd37f357dc5" title="Resources" />} {...props} />
  );
}

// id: "0a1ef865-376f-416d-a9a2-53a3fc4f0ef2"
// title: "About Us - Post Slug"
// type: :text
// key: "postSlug"
// parent_id: nil
export const PostSlug0a1ef865376f416dA9a253a3fc4f0ef2 = "about-us-posts";

// id: "1e0dbd9d-d36b-46f6-982b-52a4399eaf84"
// title: "Marketing Cards Body"
// type: :html
// key: "marketing-body"
// parent_id: nil
export function MarketingBody1e0dbd9dD36b46f6982b52a4399eaf84(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MarketingBody1e0dbd9dD36b46f6982b52a4399eaf84 page-body`}>
      {props["marketing-cards"] || <MarketingCards parentTag={buildClassName("MarketingCardsA09a7637A052486d8cee36f1357cd568", parentTag)} {...props} />}
    </div>
  );
}

// id: "bc6dea7e-ccf2-4164-aee9-9484c90a4eab"
// title: "Password Reset Page"
// type: :reference
// key: "password-reset-page"
// parent_id: nil
export function PasswordResetPageBc6dea7eCcf24164Aee99484c90a4eab(props: any) {
  return (
    <LoginPageD757053e75114d40Aad0E45e1d75a5eb parentTag="PasswordResetPageBc6dea7eCcf24164Aee99484c90a4eab" {...props} />
  );
}

// id: "59a8c267-440e-43cc-9a16-e405574163c5"
// title: "Label"
// type: :reference
// key: "label"
// parent_id: nil
export function Label59a8c267440e43cc9a16E405574163c5(props: any) {
  return (
    <DefaultLogo52c87ec8B4c143b4885079fe82f5cdf5 parentTag="Label59a8c267440e43cc9a16E405574163c5" {...props} />
  );
}

// id: "2dfae297-fc9c-402a-ba2a-fed47f02733e"
// title: "Message Pages"
// type: :html
// key: "message-pages"
// parent_id: nil
export function MessagePages2dfae297Fc9c402aBa2aFed47f02733e(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MessagePages2dfae297Fc9c402aBa2aFed47f02733e`}>
      {props["header"] || <MembersAreaHeaderB98a193a0c9c4dbcA1c9Bb8cade3510d parentTag="Header2bb3a118B2804e2a944e97bd9171f169" {...props} />}
      {props.children}
    </div>
  );
}

// id: "e0015401-c2d8-41b1-989c-fc92dda05889"
// title: "Join Us Page"
// type: :reference
// key: "join-us-page"
// parent_id: nil
export function JoinUsPageE0015401C2d841b1989cFc92dda05889(props: any) {
  return (
    <SecondaryPage9876ac9368a849929b915393e6e91d40 parentTag="JoinUsPageE0015401C2d841b1989cFc92dda05889" header={<SecondaryMarketingHeaderC9b608a9Cda54d6c8fa5Db800f84de9c parentTag="Header5e83fbacDa144ef28a03129003878342" title="Join Us" />} {...props} />
  );
}

// id: "a6c22cdf-3d7e-45e6-9a24-7c2f1db0e5e6"
// title: "Common Footer"
// type: :reference
// key: "footer"
// parent_id: nil
export function FooterA6c22cdf3d7e45e69a247c2f1db0e5e6(props: any) {
  return (
    <DefaultFooter1e348db1C3234891830d01c29b55fc36 parentTag="FooterA6c22cdf3d7e45e69a247c2f1db0e5e6" {...props} />
  );
}

// id: "9455e651-4256-47b6-b5f7-497e12561079"
// title: "Members Area"
// type: :html
// key: "members-area-default"
// parent_id: nil
export function MembersAreaDefault9455e651425647b6B5f7497e12561079(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MembersAreaDefault9455e651425647b6B5f7497e12561079 page`}>
      {props["header"] || <MembersAreaHeaderB98a193a0c9c4dbcA1c9Bb8cade3510d parentTag="HeaderC76247ef30e74d47B3a2Dcc71d066984" navMenuSlug="members-primary-nav" {...props} />}
      {props["footer"] || <DefaultFooter1e348db1C3234891830d01c29b55fc36 parentTag="FooterD13dfd186dc84f3486d98881cf39ab7d" {...props} />}
    </div>
  );
}

// id: "693a5d67-7b29-409c-8c8c-db8c908f3b91"
// title: "About Us"
// type: :html
// key: "about-us-page"
// parent_id: nil
export function AboutUsPage693a5d677b29409c8c8cDb8c908f3b91(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutUsPage693a5d677b29409c8c8cDb8c908f3b91 page`}>
      {props["header"] || <SecondaryMarketingHeaderC9b608a9Cda54d6c8fa5Db800f84de9c parentTag="HeaderC648ca79Cda04f81998dD78f69980ad1" title="About Us" {...props} />}
      {props["body"] || <MarketingBody1e0dbd9dD36b46f6982b52a4399eaf84 parentTag="Body367d50a0511a4bcb95dc4ac605946091" postSlug="about-page-posts" {...props} />}
      {props["footer"] || <DefaultFooter1e348db1C3234891830d01c29b55fc36 parentTag="Footer8af7926fAc6e49948305A48217b21445" {...props} />}
    </div>
  );
}

// id: "c3ada224-d6fe-4378-b5c8-95106ed3b3b2"
// title: "Marketing Cards"
// type: :gf_preset
// key: "marketing-cards"
// parent_id: nil
export function MarketingCardsC3ada224D6fe4378B5c895106ed3b3b2(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <MarketingCards parentTag={buildClassName("MarketingCardsC3ada224D6fe4378B5c895106ed3b3b2", parentTag)} buttonClass="inline-block px-12 py-3 hover:brightness-110 transition duration" {...props} />
  );
}

// id: "ea38726d-9a97-4b07-b5e2-13f4d72abae7"
// title: "Signup Pages"
// type: :reference
// key: "signup-pages"
// parent_id: nil
export function SignupPagesEa38726d9a974b07B5e213f4d72abae7(props: any) {
  return (
    <SecondaryPage9876ac9368a849929b915393e6e91d40 parentTag="SignupPagesEa38726d9a974b07B5e213f4d72abae7" header={<SecondaryMarketingHeaderC9b608a9Cda54d6c8fa5Db800f84de9c parentTag="Header19ca2622C68641faAc8eD4aeda379c63" title="Join Us" />} {...props} />
  );
}

// id: "cf7cbbf5-6b6f-4163-b2eb-a11686a699b0"
// title: "Member Pages"
// type: :html
// key: "member-pages"
// parent_id: nil
export function MemberPagesCf7cbbf56b6f4163B2ebA11686a699b0(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MemberPagesCf7cbbf56b6f4163B2ebA11686a699b0`}>
      {props["header"] || <MembersAreaHeaderB98a193a0c9c4dbcA1c9Bb8cade3510d parentTag="Header3a3a3e2bC0e84dc2B966B59f18568ff1" {...props} />}
      {props["secondary-nav"] || <SecondaryNavigation parentTag={buildClassName("SecondaryNavCf74f3e6Cadc466397e9A44c53ae2b15", parentTag)} {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooter1e348db1C3234891830d01c29b55fc36 parentTag="FooterA2ef0432786245b0885dC767e6862ebb" {...props} />}
    </div>
  );
}

// id: "92578d29-755d-49b2-87d9-0f760cbd0660"
// title: "Members Header"
// type: :reference
// key: "header"
// parent_id: nil
export function Header92578d29755d49b287d90f760cbd0660(props: any) {
  return (
    <MembersAreaHeaderB98a193a0c9c4dbcA1c9Bb8cade3510d parentTag="Header92578d29755d49b287d90f760cbd0660" {...props} />
  );
}

// id: "720afe3b-47ca-4fbb-93dd-a775f0583e30"
// title: "Default Header Reference"
// type: :reference
// key: "default-header"
// parent_id: nil
export function DefaultHeader720afe3b47ca4fbb93ddA775f0583e30(props: any) {
  return (
    <ShortHeaderB208e88eA1074e318c50Dcadc03155e2 parentTag="DefaultHeader720afe3b47ca4fbb93ddA775f0583e30" navMenuSlug="marketing-primary-nav" title {...props} />
  );
}

// id: "ede6772c-d4c8-42e4-adcd-7331f541f1c3"
// title: "Home Page Hero"
// type: :html
// key: "home-page-hero"
// parent_id: nil
export function HomePageHeroEde6772cD4c842e4Adcd7331f541f1c3(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePageHeroEde6772cD4c842e4Adcd7331f541f1c3 hero`}>
      <div className="title">
        Learn. Play. Grow.
      </div>
      <div className="body">
        Our mission is to change the world by promoting healthy sexuality through community and education.
      </div>
      <div className="button">
        {props["button"] || <Button parentTag={buildClassName("ButtonCe45e23dA234480eBc0e83d1e3d50a0f", parentTag)} href="/join" label="Join Us" {...props} />}
      </div>
    </div>
  );
}

// id: "9876ac93-68a8-4992-9b91-5393e6e91d40"
// title: "Secondary Page"
// type: :html
// key: "secondary-page"
// parent_id: nil
export function SecondaryPage9876ac9368a849929b915393e6e91d40(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryPage9876ac9368a849929b915393e6e91d40`}>
      {props["header"] || <SecondaryMarketingHeaderC9b608a9Cda54d6c8fa5Db800f84de9c parentTag="Header714da320A07c4f7dB95cBa5f4e0437fe" title="Secondary Page" {...props} />}
      <div className="text-content">
        {props.children}
      </div>
      {props["footer"] || <DefaultFooter1e348db1C3234891830d01c29b55fc36 parentTag="FooterE052fbadC21c48d384704ece351ffce1" {...props} />}
    </div>
  );
}

// id: "d8f5ac41-9ff6-4150-8ee7-7992f959d315"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "a3a72a3d-17d2-45ae-9eb1-4a4e5f00d193"
export function HeroD8f5ac419ff641508ee77992f959d315(props: any) {
  return (
    <HomePageHeroEde6772cD4c842e4Adcd7331f541f1c3 parentTag="HeroD8f5ac419ff641508ee77992f959d315" {...props} />
  );
}

// id: "8acd670f-cb65-454e-890a-52a75d4f39e3"
// title: ""
// type: :text
// key: "buttonClass"
// parent_id: "c3ada224-d6fe-4378-b5c8-95106ed3b3b2"
export const ButtonClass8acd670fCb65454e890a52a75d4f39e3 = "inline-block px-12 py-3 hover:brightness-110 transition duration";

// id: "fe727426-85d3-48d2-81b8-fe6608a50994"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "c9b608a9-cda5-4d6c-8fa5-db800f84de9c"
export function HeroFe72742685d348d281b8Fe6608a50994(props: any) {
  return (
    <SecondaryMarketingHero178537bd662d467e9c0d9823640bae4f parentTag="HeroFe72742685d348d281b8Fe6608a50994" title="Secondary Marketing Title 2" {...props} />
  );
}

// id: "c648ca79-cda0-4f81-998d-d78f69980ad1"
// title: ""
// type: :reference
// key: "header"
// parent_id: "693a5d67-7b29-409c-8c8c-db8c908f3b91"
export function HeaderC648ca79Cda04f81998dD78f69980ad1(props: any) {
  return (
    <SecondaryMarketingHeaderC9b608a9Cda54d6c8fa5Db800f84de9c parentTag="HeaderC648ca79Cda04f81998dD78f69980ad1" title="About Us" {...props} />
  );
}

// id: "68ffb37f-a775-4482-b596-a487c6a14ebe"
// title: ""
// type: :gf_preset
// key: "groupflow"
// parent_id: "1e348db1-c323-4891-830d-01c29b55fc36"
export function Groupflow68ffb37fA7754482B596A487c6a14ebe(props: any) {
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupFlowTag parentTag={buildClassName("Groupflow68ffb37fA7754482B596A487c6a14ebe", parentTag)} {...props} />
  );
}

// id: "5a3c4e27-4ab9-4789-aa5a-ead7fee7de9f"
// title: ""
// type: :text
// key: "url"
// parent_id: "07c1dfd1-edab-4c2b-903e-fcef0108f33e"
export const Url5a3c4e274ab94789Aa5aEad7fee7de9f = "/";

// id: "fe3bf2c8-f0bd-465d-a5c0-31eedd371a16"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "3f197034-93a8-4fa7-b44e-ce8cfe9524a6"
export const NavMenuSlugFe3bf2c8F0bd465dA5c031eedd371a16 = "marketing-primary-nav";

// id: "c9b608a9-cda5-4d6c-8fa5-db800f84de9c"
// title: "Secondary Marketing Header"
// type: :html
// key: "secondary-marketing-header"
// parent_id: nil
export function SecondaryMarketingHeaderC9b608a9Cda54d6c8fa5Db800f84de9c(props: any) {
  const { parentTag, ...rest } = props;
  props = { title: "Secondary Marketing Title 1", ...rest };
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHeaderC9b608a9Cda54d6c8fa5Db800f84de9c site-header`}>
      <div className="background-color" />
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTop09bac254B4854ec3B6b35654aee06157 parentTag="HeaderTop4efd28f7F61f4ac7Ac8e5379eb47f5b5" {...props} />}
      </div>
      {props["hero"] || <SecondaryMarketingHero178537bd662d467e9c0d9823640bae4f parentTag="HeroFe72742685d348d281b8Fe6608a50994" title="Secondary Marketing Title 2" {...props} />}
    </div>
  );
}

// id: "69fbadec-03a4-454a-bf2a-2afaedecb57a"
// title: "Page Quote"
// type: :html
// key: "page-quote"
// parent_id: nil
export function PageQuote69fbadec03a4454aBf2a2afaedecb57a(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PageQuote69fbadec03a4454aBf2a2afaedecb57a`}>
      <div>
      </div>
      <div>
      </div>
    </div>
  );
}

// id: "a13a9fd7-c17a-4245-bbfd-0d9c864884f9"
// title: "Login Footer"
// type: :reference
// key: "footer"
// parent_id: "d757053e-7511-4d40-aad0-e45e1d75a5eb"
export function FooterA13a9fd7C17a4245Bbfd0d9c864884f9(props: any) {
  return (
    <DefaultFooter1e348db1C3234891830d01c29b55fc36 parentTag="FooterA13a9fd7C17a4245Bbfd0d9c864884f9" {...props} />
  );
}

// id: "595f20be-1e53-4d82-b291-e43dbdba1979"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "b208e88e-a107-4e31-8c50-dcadc03155e2"
export const BackgroundImage595f20be1e534d82B291E43dbdba1979 = "https://mp1md-pub.s3.amazonaws.com/spla/images/spla-skyline.jpeg";

// id: "d636e571-8ecb-4fa6-ae60-dcd910eed79e"
// title: ""
// type: :html
// key: "social-links"
// parent_id: "1e348db1-c323-4891-830d-01c29b55fc36"
export function SocialLinksD636e5718ecb4fa6Ae60Dcd910eed79e(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SocialLinksD636e5718ecb4fa6Ae60Dcd910eed79e flex flex-row justify-center gap-6 flex flex-row justify-center gap-6`}>
      <a className="hover:brightness-110" href="https://www.instagram.com/sexposla/">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Instagram icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M18.5769 0C8.34616 0 0 8.34616 0 18.5769V45.4423C0 55.6538 8.34616 64 18.5769 64H45.4423C55.6538 64 64 55.6539 64 45.4231V18.5769C64 8.34616 55.6539 0 45.4231 0H18.5769ZM18.5769 4.92308H45.4231C53 4.92308 59.0769 11 59.0769 18.5769V45.4231C59.0769 53 53 59.0769 45.4423 59.0769H18.5769C11 59.0769 4.92308 53 4.92308 45.4423V18.5769C4.92308 11 11 4.92308 18.5769 4.92308ZM51.6923 9.84615C50.3269 9.84615 49.2308 10.9423 49.2308 12.3077C49.2308 13.6731 50.3269 14.7692 51.6923 14.7692C53.0577 14.7692 54.1538 13.6731 54.1538 12.3077C54.1538 10.9423 53.0577 9.84615 51.6923 9.84615ZM32 14.7692C22.5192 14.7692 14.7692 22.5192 14.7692 32C14.7692 41.4808 22.5192 49.2308 32 49.2308C41.4808 49.2308 49.2308 41.4808 49.2308 32C49.2308 22.5192 41.4808 14.7692 32 14.7692ZM32 19.6923C38.8269 19.6923 44.3077 25.1731 44.3077 32C44.3077 38.8269 38.8269 44.3077 32 44.3077C25.1731 44.3077 19.6923 38.8269 19.6923 32C19.6923 25.1731 25.1731 19.6923 32 19.6923Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://www.facebook.com/groups/1104997742859454">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Facebook icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M32 0C14.356 0 0 14.356 0 32C0 49.644 14.356 64 32 64C49.644 64 64 49.644 64 32C64 14.356 49.644 0 32 0ZM32 4.92308C46.9833 4.92308 59.0769 17.0167 59.0769 32C59.0769 45.6189 49.0711 56.8173 36 58.75V39.8269H43.6442L44.8462 32.0673H36V27.8173C36 24.5927 37.0495 21.7308 40.0673 21.7308H44.9135V14.9519C44.0618 14.8387 42.2625 14.5865 38.8558 14.5865C31.7468 14.5865 27.5769 18.3428 27.5769 26.8942V32.0577H20.2692V39.8269H27.5865V58.6827C14.719 56.5766 4.92308 45.4748 4.92308 32C4.92308 17.0167 17.0167 4.92308 32 4.92308Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://twitter.com/sexposla">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Twitter icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M8 0C3.61317 0 0 3.61317 0 8V56C0 60.3868 3.61317 64 8 64H56C60.3868 64 64 60.3868 64 56V8C64 3.61317 60.3868 0 56 0H8ZM8 5.33333H56C57.5012 5.33333 58.6667 6.49883 58.6667 8V56C58.6667 57.5012 57.5012 58.6667 56 58.6667H8C6.49883 58.6667 5.33333 57.5012 5.33333 56V8C5.33333 6.49883 6.49883 5.33333 8 5.33333ZM41.25 16C36.754 16 33.0988 19.6552 33.1042 24.1458C33.1042 24.9832 33.3958 25.5442 33.3958 26.1042C26.6545 25.8215 21.0557 22.4608 17.125 17.6875C16.2823 18.8128 16 20.2119 16 21.6146C16 24.4199 17.0931 26.6674 19.3438 28.6354C17.8504 28.4328 17.1253 28.0704 16 27.5104C16 31.4411 18.5029 34.527 22.1563 35.375C22.1563 35.375 20.7489 35.6563 19.9063 35.6563C19.2663 35.6563 18.5 35.375 18.5 35.375C19.6253 38.463 22.43 40.9896 26.0833 40.9896C23.278 42.9576 19.6221 44.3542 15.9688 44.3542H14C16.6667 46.6688 20.6635 48 26.3542 48C41.2395 48 49.3958 35.6563 49.3958 24.9896V23.8646C50.8038 22.7393 52.2027 21.3365 53.3333 19.6458C51.648 20.4938 50.2428 20.7694 48.5521 21.0521C50.2374 19.9321 51.6483 18.5252 52.2083 16.5625C50.8057 17.3998 49.1243 18.2382 47.1563 18.5208C45.7483 16.8408 43.5007 16 41.25 16Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://www.sexpositivelosangeles.org/resources/discord">
        <svg width="64" height="71" viewBox="0 0 64 71" fill="none" xmlns="http://www.w3.org/2000/svg" title="Discord icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M7.11111 0C3.22467 0 0 3.19494 0 7.04555V56.3644C0 60.2127 3.22079 63.4062 7.10417 63.4099L48.6875 63.5063C49.2395 63.5064 49.784 63.3792 50.2778 63.1347L58.2014 69.506C58.7228 69.9259 59.3537 70.1908 60.0208 70.27C60.688 70.3493 61.3641 70.2396 61.971 69.9538C62.5778 69.6679 63.0905 69.2175 63.4496 68.6549C63.8087 68.0922 63.9995 67.4402 64 66.7745V7.04555C64 3.19494 60.7753 0 56.8889 0H7.11111ZM7.11111 7.04555H56.8889V59.3711L50.1667 53.97C49.6617 53.5648 49.0543 53.3044 48.4104 53.2172C47.7666 53.1299 47.1109 53.2191 46.5146 53.4752C45.9184 53.7312 45.4043 54.1442 45.0283 54.6693C44.6523 55.1945 44.4288 55.8117 44.3819 56.4538L7.11806 56.3644C7.11574 56.3644 7.11343 56.3644 7.11111 56.3644V7.04555ZM27.1528 18.1643C22.559 18.6011 19.0694 21.3224 19.0694 21.3224C19.0694 21.3224 14.9333 27.2306 14.2222 38.7712C18.4036 43.5058 24.7292 43.5324 24.7292 43.5324L26.0417 41.7985C24.7012 41.3406 23.5587 40.7531 22.3889 39.8583L22.6319 39.1427C24.8186 40.1326 27.6267 40.7114 32 40.7114C36.3733 40.7114 39.1814 40.1291 41.3681 39.1427L41.6111 39.8583C40.4378 40.7566 39.2952 41.3441 37.9583 41.7985L39.2708 43.5324C39.2708 43.5324 45.5964 43.5058 49.7778 38.7712C49.0667 27.2306 44.9306 21.3224 44.9306 21.3224C44.9306 21.3224 41.2277 18.4884 36.8472 18.1643L35.9444 19.9945C34.604 19.7514 33.2053 19.5748 32 19.5748C30.7556 19.5748 29.3572 19.7415 28.0417 19.967L27.1528 18.1643ZM25.5972 28.1822C27.1617 28.1822 28.4444 29.7604 28.4444 31.705C28.4444 33.6495 27.1617 35.2277 25.5972 35.2277C24.0328 35.2277 22.7569 33.6495 22.7569 31.705C22.7569 29.7604 24.0328 28.1822 25.5972 28.1822ZM38.4028 28.1822C39.9672 28.1822 41.2431 29.7604 41.2431 31.705C41.2431 33.6495 39.9672 35.2277 38.4028 35.2277C36.8383 35.2277 35.5556 33.6495 35.5556 31.705C35.5556 29.7604 36.8383 28.1822 38.4028 28.1822Z" />
        </svg>
      </a>
    </div>
  );
}

// id: "07c1dfd1-edab-4c2b-903e-fcef0108f33e"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "09bac254-b485-4ec3-b6b3-5654aee06157"
export function LinkedLogo07c1dfd1Edab4c2b903eFcef0108f33e(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogo07c1dfd1Edab4c2b903eFcef0108f33e", parentTag)} url="/" label={<DefaultLogo52c87ec8B4c143b4885079fe82f5cdf5 parentTag="LabelBebc07ccEb164674B5eb99b4e8e201fc" alt="logo" />} className="linked-logo" {...props} />
  );
}

// id: "5e83fbac-da14-4ef2-8a03-129003878342"
// title: ""
// type: :reference
// key: "header"
// parent_id: "e0015401-c2d8-41b1-989c-fc92dda05889"
export function Header5e83fbacDa144ef28a03129003878342(props: any) {
  return (
    <SecondaryMarketingHeaderC9b608a9Cda54d6c8fa5Db800f84de9c parentTag="Header5e83fbacDa144ef28a03129003878342" title="Join Us" {...props} />
  );
}

// id: "a2ef0432-7862-45b0-885d-c767e6862ebb"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "cf7cbbf5-6b6f-4163-b2eb-a11686a699b0"
export function FooterA2ef0432786245b0885dC767e6862ebb(props: any) {
  return (
    <DefaultFooter1e348db1C3234891830d01c29b55fc36 parentTag="FooterA2ef0432786245b0885dC767e6862ebb" {...props} />
  );
}

// id: "f584a6ed-7a95-4b05-b6f5-817842ea0c5f"
// title: ""
// type: :text
// key: "href"
// parent_id: "ce45e23d-a234-480e-bc0e-83d1e3d50a0f"
export const HrefF584a6ed7a954b05B6f5817842ea0c5f = "/join";

// id: "2db74033-7e61-4686-b9b8-2b11141bef23"
// title: ""
// type: :text
// key: "title"
// parent_id: "fe727426-85d3-48d2-81b8-fe6608a50994"
export const Title2db740337e614686B9b82b11141bef23 = "Secondary Marketing Title 2";

// id: "1b85ba3a-bd24-4a96-bf1f-b44759bc9018"
// title: "HREF"
// type: :text
// key: "href"
// parent_id: nil
export const Href1b85ba3aBd244a96Bf1fB44759bc9018 = "/";

// id: "47bdaf1b-90c8-4b5c-98bf-c860b9e455ae"
// title: "Home Page"
// type: :html
// key: "home-page"
// parent_id: nil
export function HomePage47bdaf1b90c84b5c98bfC860b9e455ae(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePage47bdaf1b90c84b5c98bfC860b9e455ae page`}>
      {props["header"] || <HomeHeaderA3a72a3d17d245ae9eb14a4e5f00d193 parentTag="Header71753787976f4055843376fce7adee6d" {...props} />}
      {props["welcome-back"] || <WelcomeBackPopUp parentTag={buildClassName("WelcomeBackD621eba0880844e9Ad7aC3f781eca322", parentTag)} {...props} />}
      {props["body"] || <MarketingBody1e0dbd9dD36b46f6982b52a4399eaf84 parentTag="Body39159f9558744927B4b0E481ab163359" postSlug="home-page-posts2" {...props} />}
      {props["quote"] || <QuoteAfd9abb3285c4e1aB287Bd6631a1d79f />}
      {props["footer"] || <DefaultFooter1e348db1C3234891830d01c29b55fc36 parentTag="Footer732b68952ebf496b862533d816cb26a1" {...props} />}
    </div>
  );
}

// id: "d13dfd18-6dc8-4f34-86d9-8881cf39ab7d"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "9455e651-4256-47b6-b5f7-497e12561079"
export function FooterD13dfd186dc84f3486d98881cf39ab7d(props: any) {
  return (
    <DefaultFooter1e348db1C3234891830d01c29b55fc36 parentTag="FooterD13dfd186dc84f3486d98881cf39ab7d" {...props} />
  );
}

// id: "4efd28f7-f61f-4ac7-ac8e-5379eb47f5b5"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "c9b608a9-cda5-4d6c-8fa5-db800f84de9c"
export function HeaderTop4efd28f7F61f4ac7Ac8e5379eb47f5b5(props: any) {
  return (
    <DefaultHeaderTop09bac254B4854ec3B6b35654aee06157 parentTag="HeaderTop4efd28f7F61f4ac7Ac8e5379eb47f5b5" {...props} />
  );
}

// id: "cf74f3e6-cadc-4663-97e9-a44c53ae2b15"
// title: ""
// type: :gf_preset
// key: "secondary-nav"
// parent_id: "cf7cbbf5-6b6f-4163-b2eb-a11686a699b0"
export function SecondaryNavCf74f3e6Cadc466397e9A44c53ae2b15(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <SecondaryNavigation parentTag={buildClassName("SecondaryNavCf74f3e6Cadc466397e9A44c53ae2b15", parentTag)} {...props} />
  );
}

// id: "6aab30e0-06a1-4df8-83a7-d7bdc1ecc952"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "f9b2afb3-545e-4592-a545-c9baa8ebbc99"
export function Footer6aab30e006a14df883a7D7bdc1ecc952(props: any) {
  return (
    <DefaultFooter1e348db1C3234891830d01c29b55fc36 parentTag="Footer6aab30e006a14df883a7D7bdc1ecc952" {...props} />
  );
}

// id: "3f197034-93a8-4fa7-b44e-ce8cfe9524a6"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "09bac254-b485-4ec3-b6b3-5654aee06157"
export function Navigation3f19703493a84fa7B44eCe8cfe9524a6(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("Navigation3f19703493a84fa7B44eCe8cfe9524a6", parentTag)} navMenuSlug="marketing-primary-nav" className="navigation" {...props} />
  );
}

// id: "0a4472b6-b847-4d41-8567-acb7f860cd3a"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "c76247ef-30e7-4d47-b3a2-dcc71d066984"
export const NavMenuSlug0a4472b6B8474d418567Acb7f860cd3a = "members-primary-nav";

// id: "3422c893-bfbe-40ba-8bea-f045970a741d"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "490109df-4121-4520-a397-d6e783744712"
export const NavMenuSlug3422c893Bfbe40ba8beaF045970a741d = "footer-nav";

// id: "de51cc66-75b9-47bb-ac98-0cd5b7196c68"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "14b5bd02-62bd-438f-8cb1-c2325eedf53e"
export const NavMenuSlugDe51cc6675b947bbAc980cd5b7196c68 = "members-primary-nav";

// id: "d757053e-7511-4d40-aad0-e45e1d75a5eb"
// title: "Login Page"
// type: :html
// key: "login-page"
// parent_id: nil
export function LoginPageD757053e75114d40Aad0E45e1d75a5eb(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <>
      {props["header"] || <SecondaryMarketingHeaderC9b608a9Cda54d6c8fa5Db800f84de9c parentTag="HeaderB8383ae875d34d25B1530fea0b1bec8f" title="Login" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooter1e348db1C3234891830d01c29b55fc36 parentTag="FooterA13a9fd7C17a4245Bbfd0d9c864884f9" {...props} />}
    </>
  );
}

// id: "178537bd-662d-467e-9c0d-9823640bae4f"
// title: "Secondary Marketing Hero"
// type: :html
// key: "secondary-marketing-hero"
// parent_id: nil
export function SecondaryMarketingHero178537bd662d467e9c0d9823640bae4f(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHero178537bd662d467e9c0d9823640bae4f hero`}>
      <div className="title">
        {props["title"] || Title505813efAe5c403685a3Cb82f9491ebb}
      </div>
    </div>
  );
}

// id: "f0032d8d-4b99-455a-b00f-fc453e4c4d74"
// title: ""
// type: :text
// key: "alt"
// parent_id: "52c87ec8-b4c1-43b4-8850-79fe82f5cdf5"
export const AltF0032d8d4b99455aB00fFc453e4c4d74 = "logo";

// id: "71753787-976f-4055-8433-76fce7adee6d"
// title: ""
// type: :reference
// key: "header"
// parent_id: "47bdaf1b-90c8-4b5c-98bf-c860b9e455ae"
export function Header71753787976f4055843376fce7adee6d(props: any) {
  return (
    <HomeHeaderA3a72a3d17d245ae9eb14a4e5f00d193 parentTag="Header71753787976f4055843376fce7adee6d" {...props} />
  );
}

// id: "e052fbad-c21c-48d3-8470-4ece351ffce1"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "9876ac93-68a8-4992-9b91-5393e6e91d40"
export function FooterE052fbadC21c48d384704ece351ffce1(props: any) {
  return (
    <DefaultFooter1e348db1C3234891830d01c29b55fc36 parentTag="FooterE052fbadC21c48d384704ece351ffce1" {...props} />
  );
}

// id: "a09a7637-a052-486d-8cee-36f1357cd568"
// title: "Marketing Cards"
// type: :gf_preset
// key: "marketing-cards"
// parent_id: "1e0dbd9d-d36b-46f6-982b-52a4399eaf84"
export function MarketingCardsA09a7637A052486d8cee36f1357cd568(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <MarketingCards parentTag={buildClassName("MarketingCardsA09a7637A052486d8cee36f1357cd568", parentTag)} {...props} />
  );
}

// id: "19ca2622-c686-41fa-ac8e-d4aeda379c63"
// title: ""
// type: :reference
// key: "header"
// parent_id: "ea38726d-9a97-4b07-b5e2-13f4d72abae7"
export function Header19ca2622C68641faAc8eD4aeda379c63(props: any) {
  return (
    <SecondaryMarketingHeaderC9b608a9Cda54d6c8fa5Db800f84de9c parentTag="Header19ca2622C68641faAc8eD4aeda379c63" title="Join Us" {...props} />
  );
}

// id: "bebc07cc-eb16-4674-b5eb-99b4e8e201fc"
// title: ""
// type: :reference
// key: "label"
// parent_id: "07c1dfd1-edab-4c2b-903e-fcef0108f33e"
export function LabelBebc07ccEb164674B5eb99b4e8e201fc(props: any) {
  return (
    <DefaultLogo52c87ec8B4c143b4885079fe82f5cdf5 parentTag="LabelBebc07ccEb164674B5eb99b4e8e201fc" {...props} />
  );
}

// id: "d458c1f0-a1f3-43ee-bb17-1e3d59b8c9b8"
// title: ""
// type: :text
// key: "title"
// parent_id: "19ca2622-c686-41fa-ac8e-d4aeda379c63"
export const TitleD458c1f0A1f343eeBb171e3d59b8c9b8 = "Join Us";

// id: "037573e8-18e1-4a2c-a65c-87aabbe352a6"
// title: "About Page PostSlug"
// type: :text
// key: "postSlug"
// parent_id: "367d50a0-511a-4bcb-95dc-4ac605946091"
export const PostSlug037573e818e14a2cA65c87aabbe352a6 = "about-page-posts";

// id: "f9b2afb3-545e-4592-a545-c9baa8ebbc99"
// title: "Public Events Page"
// type: :html
// key: "public-events-page"
// parent_id: nil
export function PublicEventsPageF9b2afb3545e4592A545C9baa8ebbc99(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PublicEventsPageF9b2afb3545e4592A545C9baa8ebbc99`}>
      {props["header"] || <SecondaryMarketingHeaderC9b608a9Cda54d6c8fa5Db800f84de9c parentTag="HeaderCa9243ca0cf24559B40482b106053663" title="Public Events" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooter1e348db1C3234891830d01c29b55fc36 parentTag="Footer6aab30e006a14df883a7D7bdc1ecc952" {...props} />}
    </div>
  );
}

// id: "a3a72a3d-17d2-45ae-9eb1-4a4e5f00d193"
// title: "Home Header"
// type: :html
// key: "home-header"
// parent_id: nil
export function HomeHeaderA3a72a3d17d245ae9eb14a4e5f00d193(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomeHeaderA3a72a3d17d245ae9eb14a4e5f00d193 site-header`}>
      <div className="background-color" />
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTop09bac254B4854ec3B6b35654aee06157 parentTag="HeaderTopEfd840d70f9a4775A03d8b2e9aba51d0" {...props} />}
      </div>
      {props["hero"] || <HomePageHeroEde6772cD4c842e4Adcd7331f541f1c3 parentTag="HeroD8f5ac419ff641508ee77992f959d315" {...props} />}
    </div>
  );
}

// id: "505813ef-ae5c-4036-85a3-cb82f9491ebb"
// title: ""
// type: :text
// key: "title"
// parent_id: "178537bd-662d-467e-9c0d-9823640bae4f"
export const Title505813efAe5c403685a3Cb82f9491ebb = "Default Title";

// id: "7217c391-01c3-47b1-babe-8d180b35627f"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "b98a193a-0c9c-4dbc-a1c9-bb8cade3510d"
export function HeaderTop7217c39101c347b1Babe8d180b35627f(props: any) {
  return (
    <DefaultHeaderTop09bac254B4854ec3B6b35654aee06157 parentTag="HeaderTop7217c39101c347b1Babe8d180b35627f" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "490109df-4121-4520-a397-d6e783744712"
// title: ""
// type: :gf_preset
// key: "links"
// parent_id: "1e348db1-c323-4891-830d-01c29b55fc36"
export function Links490109df41214520A397D6e783744712(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Navigation parentTag={buildClassName("Links490109df41214520A397D6e783744712", parentTag)} navMenuSlug="footer-nav" {...props} />
  );
}

// id: "ca9243ca-0cf2-4559-b404-82b106053663"
// title: ""
// type: :reference
// key: "header"
// parent_id: "f9b2afb3-545e-4592-a545-c9baa8ebbc99"
export function HeaderCa9243ca0cf24559B40482b106053663(props: any) {
  return (
    <SecondaryMarketingHeaderC9b608a9Cda54d6c8fa5Db800f84de9c parentTag="HeaderCa9243ca0cf24559B40482b106053663" title="Public Events" {...props} />
  );
}

// id: "8af7926f-ac6e-4994-8305-a48217b21445"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "693a5d67-7b29-409c-8c8c-db8c908f3b91"
export function Footer8af7926fAc6e49948305A48217b21445(props: any) {
  return (
    <DefaultFooter1e348db1C3234891830d01c29b55fc36 parentTag="Footer8af7926fAc6e49948305A48217b21445" {...props} />
  );
}

// id: "9c8febf2-9470-4da3-af59-8b9c94a1c8ba"
// title: ""
// type: :text
// key: "title"
// parent_id: "5e83fbac-da14-4ef2-8a03-129003878342"
export const Title9c8febf294704da3Af598b9c94a1c8ba = "Join Us";

// id: "98f839b9-caa4-4d47-b1dc-0e25ee298b0e"
// title: ""
// type: :text
// key: "label"
// parent_id: "ce45e23d-a234-480e-bc0e-83d1e3d50a0f"
export const Label98f839b9Caa44d47B1dc0e25ee298b0e = "Join Us";

// id: "ca40ec37-e46d-4546-b7bc-3a9f4fbc27d7"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "7217c391-01c3-47b1-babe-8d180b35627f"
export const NavMenuSlugCa40ec37E46d4546B7bc3a9f4fbc27d7 = "members-primary-nav";

// id: "09bac254-b485-4ec3-b6b3-5654aee06157"
// title: "Header Area Top"
// type: :html
// key: "default-header-top"
// parent_id: nil
export function DefaultHeaderTop09bac254B4854ec3B6b35654aee06157(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultHeaderTop09bac254B4854ec3B6b35654aee06157 header-top`}>
      {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogo07c1dfd1Edab4c2b903eFcef0108f33e", parentTag)} url="/" label={<DefaultLogo52c87ec8B4c143b4885079fe82f5cdf5 parentTag="LabelBebc07ccEb164674B5eb99b4e8e201fc" alt="logo" />} className="linked-logo" {...props} />}
      {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("Navigation3f19703493a84fa7B44eCe8cfe9524a6", parentTag)} navMenuSlug="marketing-primary-nav" className="navigation" {...props} />}
    </div>
  );
}

// id: "b8383ae8-75d3-4d25-b153-0fea0b1bec8f"
// title: "Login Header"
// type: :reference
// key: "header"
// parent_id: "d757053e-7511-4d40-aad0-e45e1d75a5eb"
export function HeaderB8383ae875d34d25B1530fea0b1bec8f(props: any) {
  return (
    <SecondaryMarketingHeaderC9b608a9Cda54d6c8fa5Db800f84de9c parentTag="HeaderB8383ae875d34d25B1530fea0b1bec8f" title="Login" {...props} />
  );
}

// id: "afd9abb3-285c-4e1a-b287-bd6631a1d79f"
// title: ""
// type: :html
// key: "quote"
// parent_id: "47bdaf1b-90c8-4b5c-98bf-c860b9e455ae"
export function QuoteAfd9abb3285c4e1aB287Bd6631a1d79f(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} QuoteAfd9abb3285c4e1aB287Bd6631a1d79f PageQuote`}>
      <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/fDIDlI3qk75Cn3iS_regular.jpeg" className="image opacity" />
      <div className="TextContent">
        Our mission is to change the world by promoting healthy intimacy through community and education.
      </div>
    </div>
  );
}

// id: "f8bd2435-7eb8-404d-b267-d8ee5a37d50a"
// title: "Public Primary Navigation"
// type: :text
// key: "navMenuSlug"
// parent_id: "720afe3b-47ca-4fbb-93dd-a775f0583e30"
export const NavMenuSlugF8bd24357eb8404dB267D8ee5a37d50a = "marketing-primary-nav";

// id: "732b6895-2ebf-496b-8625-33d816cb26a1"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "47bdaf1b-90c8-4b5c-98bf-c860b9e455ae"
export function Footer732b68952ebf496b862533d816cb26a1(props: any) {
  return (
    <DefaultFooter1e348db1C3234891830d01c29b55fc36 parentTag="Footer732b68952ebf496b862533d816cb26a1" {...props} />
  );
}

// id: "275ba825-5295-46c9-91b3-2632f39a6535"
// title: ""
// type: :html
// key: "social-links"
// parent_id: "1e348db1-c323-4891-830d-01c29b55fc36"
export function SocialLinks275ba825529546c991b32632f39a6535(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SocialLinks275ba825529546c991b32632f39a6535 flex flex-row justify-center gap-6 flex flex-row justify-center gap-6`}>
      <a className="hover:brightness-110" href="https://www.instagram.com/sexposla/">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Instagram icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M18.5769 0C8.34616 0 0 8.34616 0 18.5769V45.4423C0 55.6538 8.34616 64 18.5769 64H45.4423C55.6538 64 64 55.6539 64 45.4231V18.5769C64 8.34616 55.6539 0 45.4231 0H18.5769ZM18.5769 4.92308H45.4231C53 4.92308 59.0769 11 59.0769 18.5769V45.4231C59.0769 53 53 59.0769 45.4423 59.0769H18.5769C11 59.0769 4.92308 53 4.92308 45.4423V18.5769C4.92308 11 11 4.92308 18.5769 4.92308ZM51.6923 9.84615C50.3269 9.84615 49.2308 10.9423 49.2308 12.3077C49.2308 13.6731 50.3269 14.7692 51.6923 14.7692C53.0577 14.7692 54.1538 13.6731 54.1538 12.3077C54.1538 10.9423 53.0577 9.84615 51.6923 9.84615ZM32 14.7692C22.5192 14.7692 14.7692 22.5192 14.7692 32C14.7692 41.4808 22.5192 49.2308 32 49.2308C41.4808 49.2308 49.2308 41.4808 49.2308 32C49.2308 22.5192 41.4808 14.7692 32 14.7692ZM32 19.6923C38.8269 19.6923 44.3077 25.1731 44.3077 32C44.3077 38.8269 38.8269 44.3077 32 44.3077C25.1731 44.3077 19.6923 38.8269 19.6923 32C19.6923 25.1731 25.1731 19.6923 32 19.6923Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://www.facebook.com/groups/1104997742859454">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Facebook icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M32 0C14.356 0 0 14.356 0 32C0 49.644 14.356 64 32 64C49.644 64 64 49.644 64 32C64 14.356 49.644 0 32 0ZM32 4.92308C46.9833 4.92308 59.0769 17.0167 59.0769 32C59.0769 45.6189 49.0711 56.8173 36 58.75V39.8269H43.6442L44.8462 32.0673H36V27.8173C36 24.5927 37.0495 21.7308 40.0673 21.7308H44.9135V14.9519C44.0618 14.8387 42.2625 14.5865 38.8558 14.5865C31.7468 14.5865 27.5769 18.3428 27.5769 26.8942V32.0577H20.2692V39.8269H27.5865V58.6827C14.719 56.5766 4.92308 45.4748 4.92308 32C4.92308 17.0167 17.0167 4.92308 32 4.92308Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://twitter.com/sexposla">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Twitter icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M8 0C3.61317 0 0 3.61317 0 8V56C0 60.3868 3.61317 64 8 64H56C60.3868 64 64 60.3868 64 56V8C64 3.61317 60.3868 0 56 0H8ZM8 5.33333H56C57.5012 5.33333 58.6667 6.49883 58.6667 8V56C58.6667 57.5012 57.5012 58.6667 56 58.6667H8C6.49883 58.6667 5.33333 57.5012 5.33333 56V8C5.33333 6.49883 6.49883 5.33333 8 5.33333ZM41.25 16C36.754 16 33.0988 19.6552 33.1042 24.1458C33.1042 24.9832 33.3958 25.5442 33.3958 26.1042C26.6545 25.8215 21.0557 22.4608 17.125 17.6875C16.2823 18.8128 16 20.2119 16 21.6146C16 24.4199 17.0931 26.6674 19.3438 28.6354C17.8504 28.4328 17.1253 28.0704 16 27.5104C16 31.4411 18.5029 34.527 22.1563 35.375C22.1563 35.375 20.7489 35.6563 19.9063 35.6563C19.2663 35.6563 18.5 35.375 18.5 35.375C19.6253 38.463 22.43 40.9896 26.0833 40.9896C23.278 42.9576 19.6221 44.3542 15.9688 44.3542H14C16.6667 46.6688 20.6635 48 26.3542 48C41.2395 48 49.3958 35.6563 49.3958 24.9896V23.8646C50.8038 22.7393 52.2027 21.3365 53.3333 19.6458C51.648 20.4938 50.2428 20.7694 48.5521 21.0521C50.2374 19.9321 51.6483 18.5252 52.2083 16.5625C50.8057 17.3998 49.1243 18.2382 47.1563 18.5208C45.7483 16.8408 43.5007 16 41.25 16Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://www.sexpositivelosangeles.org/resources/discord">
        <svg width="64" height="71" viewBox="0 0 64 71" fill="none" xmlns="http://www.w3.org/2000/svg" title="Discord icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M7.11111 0C3.22467 0 0 3.19494 0 7.04555V56.3644C0 60.2127 3.22079 63.4062 7.10417 63.4099L48.6875 63.5063C49.2395 63.5064 49.784 63.3792 50.2778 63.1347L58.2014 69.506C58.7228 69.9259 59.3537 70.1908 60.0208 70.27C60.688 70.3493 61.3641 70.2396 61.971 69.9538C62.5778 69.6679 63.0905 69.2175 63.4496 68.6549C63.8087 68.0922 63.9995 67.4402 64 66.7745V7.04555C64 3.19494 60.7753 0 56.8889 0H7.11111ZM7.11111 7.04555H56.8889V59.3711L50.1667 53.97C49.6617 53.5648 49.0543 53.3044 48.4104 53.2172C47.7666 53.1299 47.1109 53.2191 46.5146 53.4752C45.9184 53.7312 45.4043 54.1442 45.0283 54.6693C44.6523 55.1945 44.4288 55.8117 44.3819 56.4538L7.11806 56.3644C7.11574 56.3644 7.11343 56.3644 7.11111 56.3644V7.04555ZM27.1528 18.1643C22.559 18.6011 19.0694 21.3224 19.0694 21.3224C19.0694 21.3224 14.9333 27.2306 14.2222 38.7712C18.4036 43.5058 24.7292 43.5324 24.7292 43.5324L26.0417 41.7985C24.7012 41.3406 23.5587 40.7531 22.3889 39.8583L22.6319 39.1427C24.8186 40.1326 27.6267 40.7114 32 40.7114C36.3733 40.7114 39.1814 40.1291 41.3681 39.1427L41.6111 39.8583C40.4378 40.7566 39.2952 41.3441 37.9583 41.7985L39.2708 43.5324C39.2708 43.5324 45.5964 43.5058 49.7778 38.7712C49.0667 27.2306 44.9306 21.3224 44.9306 21.3224C44.9306 21.3224 41.2277 18.4884 36.8472 18.1643L35.9444 19.9945C34.604 19.7514 33.2053 19.5748 32 19.5748C30.7556 19.5748 29.3572 19.7415 28.0417 19.967L27.1528 18.1643ZM25.5972 28.1822C27.1617 28.1822 28.4444 29.7604 28.4444 31.705C28.4444 33.6495 27.1617 35.2277 25.5972 35.2277C24.0328 35.2277 22.7569 33.6495 22.7569 31.705C22.7569 29.7604 24.0328 28.1822 25.5972 28.1822ZM38.4028 28.1822C39.9672 28.1822 41.2431 29.7604 41.2431 31.705C41.2431 33.6495 39.9672 35.2277 38.4028 35.2277C36.8383 35.2277 35.5556 33.6495 35.5556 31.705C35.5556 29.7604 36.8383 28.1822 38.4028 28.1822Z" />
        </svg>
      </a>
    </div>
  );
}

// id: "ad14f008-d0fa-4e60-b98e-76aa1fff3d01"
// title: ""
// type: :text
// key: "title"
// parent_id: "c648ca79-cda0-4f81-998d-d78f69980ad1"
export const TitleAd14f008D0fa4e60B98e76aa1fff3d01 = "About Us";

// id: "9a090ddd-7ca3-4abd-b6ee-82c3fefb1517"
// title: ""
// type: :text
// key: "title"
// parent_id: "714da320-a07c-4f7d-b95c-ba5f4e0437fe"
export const Title9a090ddd7ca34abdB6ee82c3fefb1517 = "Secondary Page";

// id: "095b7ce6-e0c3-4ac3-93c6-2a019f304121"
// title: ""
// type: :text
// key: "title"
// parent_id: "ca9243ca-0cf2-4559-b404-82b106053663"
export const Title095b7ce6E0c34ac393c62a019f304121 = "Public Events";

// id: "b208e88e-a107-4e31-8c50-dcadc03155e2"
// title: "Short Header"
// type: :html
// key: "short-header"
// parent_id: nil
export function ShortHeaderB208e88eA1074e318c50Dcadc03155e2(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} ShortHeaderB208e88eA1074e318c50Dcadc03155e2 site-header`}>
      <div className="background-image" />
      <div className="header-top">
        {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogo33c9dfd2B4414723Bdce9aeb2eaf9e62", parentTag)} label={<DefaultLogo52c87ec8B4c143b4885079fe82f5cdf5 parentTag="LabelD3aa9cf8B72845348c9d38729eeb90d8" alt="logo" />} {...props} />}
        {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("Navigation14b5bd0262bd438f8cb1C2325eedf53e", parentTag)} navMenuSlug="members-primary-nav" {...props} />}
      </div>
    </div>
  );
}

// id: "33c9dfd2-b441-4723-bdce-9aeb2eaf9e62"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "b208e88e-a107-4e31-8c50-dcadc03155e2"
export function LinkedLogo33c9dfd2B4414723Bdce9aeb2eaf9e62(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogo33c9dfd2B4414723Bdce9aeb2eaf9e62", parentTag)} label={<DefaultLogo52c87ec8B4c143b4885079fe82f5cdf5 parentTag="LabelD3aa9cf8B72845348c9d38729eeb90d8" alt="logo" />} {...props} />
  );
}

// id: "e2067fae-c278-4677-9c71-499871dea633"
// title: ""
// type: :html
// key: "social-links"
// parent_id: "1e348db1-c323-4891-830d-01c29b55fc36"
export function SocialLinksE2067faeC27846779c71499871dea633(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SocialLinksE2067faeC27846779c71499871dea633 flex flex-row justify-center gap-6 flex flex-row justify-center gap-6`}>
      <a className="hover:brightness-110" href="https://www.instagram.com/sexposla/">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Instagram icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M18.5769 0C8.34616 0 0 8.34616 0 18.5769V45.4423C0 55.6538 8.34616 64 18.5769 64H45.4423C55.6538 64 64 55.6539 64 45.4231V18.5769C64 8.34616 55.6539 0 45.4231 0H18.5769ZM18.5769 4.92308H45.4231C53 4.92308 59.0769 11 59.0769 18.5769V45.4231C59.0769 53 53 59.0769 45.4423 59.0769H18.5769C11 59.0769 4.92308 53 4.92308 45.4423V18.5769C4.92308 11 11 4.92308 18.5769 4.92308ZM51.6923 9.84615C50.3269 9.84615 49.2308 10.9423 49.2308 12.3077C49.2308 13.6731 50.3269 14.7692 51.6923 14.7692C53.0577 14.7692 54.1538 13.6731 54.1538 12.3077C54.1538 10.9423 53.0577 9.84615 51.6923 9.84615ZM32 14.7692C22.5192 14.7692 14.7692 22.5192 14.7692 32C14.7692 41.4808 22.5192 49.2308 32 49.2308C41.4808 49.2308 49.2308 41.4808 49.2308 32C49.2308 22.5192 41.4808 14.7692 32 14.7692ZM32 19.6923C38.8269 19.6923 44.3077 25.1731 44.3077 32C44.3077 38.8269 38.8269 44.3077 32 44.3077C25.1731 44.3077 19.6923 38.8269 19.6923 32C19.6923 25.1731 25.1731 19.6923 32 19.6923Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://www.facebook.com/groups/1104997742859454">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Facebook icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M32 0C14.356 0 0 14.356 0 32C0 49.644 14.356 64 32 64C49.644 64 64 49.644 64 32C64 14.356 49.644 0 32 0ZM32 4.92308C46.9833 4.92308 59.0769 17.0167 59.0769 32C59.0769 45.6189 49.0711 56.8173 36 58.75V39.8269H43.6442L44.8462 32.0673H36V27.8173C36 24.5927 37.0495 21.7308 40.0673 21.7308H44.9135V14.9519C44.0618 14.8387 42.2625 14.5865 38.8558 14.5865C31.7468 14.5865 27.5769 18.3428 27.5769 26.8942V32.0577H20.2692V39.8269H27.5865V58.6827C14.719 56.5766 4.92308 45.4748 4.92308 32C4.92308 17.0167 17.0167 4.92308 32 4.92308Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://twitter.com/sexposla">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Twitter icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M8 0C3.61317 0 0 3.61317 0 8V56C0 60.3868 3.61317 64 8 64H56C60.3868 64 64 60.3868 64 56V8C64 3.61317 60.3868 0 56 0H8ZM8 5.33333H56C57.5012 5.33333 58.6667 6.49883 58.6667 8V56C58.6667 57.5012 57.5012 58.6667 56 58.6667H8C6.49883 58.6667 5.33333 57.5012 5.33333 56V8C5.33333 6.49883 6.49883 5.33333 8 5.33333ZM41.25 16C36.754 16 33.0988 19.6552 33.1042 24.1458C33.1042 24.9832 33.3958 25.5442 33.3958 26.1042C26.6545 25.8215 21.0557 22.4608 17.125 17.6875C16.2823 18.8128 16 20.2119 16 21.6146C16 24.4199 17.0931 26.6674 19.3438 28.6354C17.8504 28.4328 17.1253 28.0704 16 27.5104C16 31.4411 18.5029 34.527 22.1563 35.375C22.1563 35.375 20.7489 35.6563 19.9063 35.6563C19.2663 35.6563 18.5 35.375 18.5 35.375C19.6253 38.463 22.43 40.9896 26.0833 40.9896C23.278 42.9576 19.6221 44.3542 15.9688 44.3542H14C16.6667 46.6688 20.6635 48 26.3542 48C41.2395 48 49.3958 35.6563 49.3958 24.9896V23.8646C50.8038 22.7393 52.2027 21.3365 53.3333 19.6458C51.648 20.4938 50.2428 20.7694 48.5521 21.0521C50.2374 19.9321 51.6483 18.5252 52.2083 16.5625C50.8057 17.3998 49.1243 18.2382 47.1563 18.5208C45.7483 16.8408 43.5007 16 41.25 16Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://www.sexpositivelosangeles.org/resources/discord">
        <svg width="64" height="71" viewBox="0 0 64 71" fill="none" xmlns="http://www.w3.org/2000/svg" title="Discord icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M7.11111 0C3.22467 0 0 3.19494 0 7.04555V56.3644C0 60.2127 3.22079 63.4062 7.10417 63.4099L48.6875 63.5063C49.2395 63.5064 49.784 63.3792 50.2778 63.1347L58.2014 69.506C58.7228 69.9259 59.3537 70.1908 60.0208 70.27C60.688 70.3493 61.3641 70.2396 61.971 69.9538C62.5778 69.6679 63.0905 69.2175 63.4496 68.6549C63.8087 68.0922 63.9995 67.4402 64 66.7745V7.04555C64 3.19494 60.7753 0 56.8889 0H7.11111ZM7.11111 7.04555H56.8889V59.3711L50.1667 53.97C49.6617 53.5648 49.0543 53.3044 48.4104 53.2172C47.7666 53.1299 47.1109 53.2191 46.5146 53.4752C45.9184 53.7312 45.4043 54.1442 45.0283 54.6693C44.6523 55.1945 44.4288 55.8117 44.3819 56.4538L7.11806 56.3644C7.11574 56.3644 7.11343 56.3644 7.11111 56.3644V7.04555ZM27.1528 18.1643C22.559 18.6011 19.0694 21.3224 19.0694 21.3224C19.0694 21.3224 14.9333 27.2306 14.2222 38.7712C18.4036 43.5058 24.7292 43.5324 24.7292 43.5324L26.0417 41.7985C24.7012 41.3406 23.5587 40.7531 22.3889 39.8583L22.6319 39.1427C24.8186 40.1326 27.6267 40.7114 32 40.7114C36.3733 40.7114 39.1814 40.1291 41.3681 39.1427L41.6111 39.8583C40.4378 40.7566 39.2952 41.3441 37.9583 41.7985L39.2708 43.5324C39.2708 43.5324 45.5964 43.5058 49.7778 38.7712C49.0667 27.2306 44.9306 21.3224 44.9306 21.3224C44.9306 21.3224 41.2277 18.4884 36.8472 18.1643L35.9444 19.9945C34.604 19.7514 33.2053 19.5748 32 19.5748C30.7556 19.5748 29.3572 19.7415 28.0417 19.967L27.1528 18.1643ZM25.5972 28.1822C27.1617 28.1822 28.4444 29.7604 28.4444 31.705C28.4444 33.6495 27.1617 35.2277 25.5972 35.2277C24.0328 35.2277 22.7569 33.6495 22.7569 31.705C22.7569 29.7604 24.0328 28.1822 25.5972 28.1822ZM38.4028 28.1822C39.9672 28.1822 41.2431 29.7604 41.2431 31.705C41.2431 33.6495 39.9672 35.2277 38.4028 35.2277C36.8383 35.2277 35.5556 33.6495 35.5556 31.705C35.5556 29.7604 36.8383 28.1822 38.4028 28.1822Z" />
        </svg>
      </a>
    </div>
  );
}

// id: "cbe1aef8-e30c-4987-afe2-9fd37f357dc5"
// title: ""
// type: :reference
// key: "header"
// parent_id: "5428cbbf-9acf-44f9-b7b8-fb037b60d85b"
export function HeaderCbe1aef8E30c4987Afe29fd37f357dc5(props: any) {
  return (
    <SecondaryMarketingHeaderC9b608a9Cda54d6c8fa5Db800f84de9c parentTag="HeaderCbe1aef8E30c4987Afe29fd37f357dc5" title="Resources" {...props} />
  );
}

// id: "367d50a0-511a-4bcb-95dc-4ac605946091"
// title: ""
// type: :reference
// key: "body"
// parent_id: "693a5d67-7b29-409c-8c8c-db8c908f3b91"
export function Body367d50a0511a4bcb95dc4ac605946091(props: any) {
  return (
    <MarketingBody1e0dbd9dD36b46f6982b52a4399eaf84 parentTag="Body367d50a0511a4bcb95dc4ac605946091" postSlug="about-page-posts" {...props} />
  );
}

// id: "f4677fb4-f69f-4a2f-8888-27982a1e6077"
// title: ""
// type: :text
// key: "title"
// parent_id: "720afe3b-47ca-4fbb-93dd-a775f0583e30"
export const TitleF4677fb4F69f4a2f888827982a1e6077 = null;

// id: "42e28144-e688-4d0b-8562-c35a6ff9a841"
// title: ""
// type: :text
// key: "class"
// parent_id: "07c1dfd1-edab-4c2b-903e-fcef0108f33e"
export const Class42e28144E6884d0b8562C35a6ff9a841 = "linked-logo";

// id: "e09cc371-b46f-48b7-bebb-01f90c680afe"
// title: ""
// type: :text
// key: "class"
// parent_id: "3f197034-93a8-4fa7-b44e-ce8cfe9524a6"
export const ClassE09cc371B46f48b7Bebb01f90c680afe = "navigation";

// id: "1e348db1-c323-4891-830d-01c29b55fc36"
// title: "Footer"
// type: :html
// key: "default-footer"
// parent_id: nil
export function DefaultFooter1e348db1C3234891830d01c29b55fc36(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultFooter1e348db1C3234891830d01c29b55fc36 footer`}>
      {props["social-links"] || <SocialLinksE2067faeC27846779c71499871dea633 />}
      {props["links"] || <Navigation parentTag={buildClassName("Links490109df41214520A397D6e783744712", parentTag)} navMenuSlug="footer-nav" {...props} />}
      <div className="logo-wrapper">
        {props["logo"] || <Logo58d2cd96C6744e34807d5d4496120fd1 />}
      </div>
      {props["copyright"] || <GroupCopyright parentTag={buildClassName("CopyrightCd509031D9574997801cB34179d022c0", parentTag)} {...props} />}
      {props["groupflow"] || <GroupFlowTag parentTag={buildClassName("Groupflow68ffb37fA7754482B596A487c6a14ebe", parentTag)} {...props} />}
    </div>
  );
}

// id: "14b5bd02-62bd-438f-8cb1-c2325eedf53e"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "b208e88e-a107-4e31-8c50-dcadc03155e2"
export function Navigation14b5bd0262bd438f8cb1C2325eedf53e(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("Navigation14b5bd0262bd438f8cb1C2325eedf53e", parentTag)} navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "714da320-a07c-4f7d-b95c-ba5f4e0437fe"
// title: ""
// type: :reference
// key: "header"
// parent_id: "9876ac93-68a8-4992-9b91-5393e6e91d40"
export function Header714da320A07c4f7dB95cBa5f4e0437fe(props: any) {
  return (
    <SecondaryMarketingHeaderC9b608a9Cda54d6c8fa5Db800f84de9c parentTag="Header714da320A07c4f7dB95cBa5f4e0437fe" title="Secondary Page" {...props} />
  );
}

// id: "3a3a3e2b-c0e8-4dc2-b966-b59f18568ff1"
// title: ""
// type: :reference
// key: "header"
// parent_id: "cf7cbbf5-6b6f-4163-b2eb-a11686a699b0"
export function Header3a3a3e2bC0e84dc2B966B59f18568ff1(props: any) {
  return (
    <MembersAreaHeaderB98a193a0c9c4dbcA1c9Bb8cade3510d parentTag="Header3a3a3e2bC0e84dc2B966B59f18568ff1" {...props} />
  );
}

// id: "c76247ef-30e7-4d47-b3a2-dcc71d066984"
// title: ""
// type: :reference
// key: "header"
// parent_id: "9455e651-4256-47b6-b5f7-497e12561079"
export function HeaderC76247ef30e74d47B3a2Dcc71d066984(props: any) {
  return (
    <MembersAreaHeaderB98a193a0c9c4dbcA1c9Bb8cade3510d parentTag="HeaderC76247ef30e74d47B3a2Dcc71d066984" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "efd840d7-0f9a-4775-a03d-8b2e9aba51d0"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "a3a72a3d-17d2-45ae-9eb1-4a4e5f00d193"
export function HeaderTopEfd840d70f9a4775A03d8b2e9aba51d0(props: any) {
  return (
    <DefaultHeaderTop09bac254B4854ec3B6b35654aee06157 parentTag="HeaderTopEfd840d70f9a4775A03d8b2e9aba51d0" {...props} />
  );
}

// id: "2bb3a118-b280-4e2a-944e-97bd9171f169"
// title: ""
// type: :reference
// key: "header"
// parent_id: "2dfae297-fc9c-402a-ba2a-fed47f02733e"
export function Header2bb3a118B2804e2a944e97bd9171f169(props: any) {
  return (
    <MembersAreaHeaderB98a193a0c9c4dbcA1c9Bb8cade3510d parentTag="Header2bb3a118B2804e2a944e97bd9171f169" {...props} />
  );
}

// id: "27c1d143-ccdf-419f-ae92-9842beec9877"
// title: ""
// type: :text
// key: "title"
// parent_id: "cbe1aef8-e30c-4987-afe2-9fd37f357dc5"
export const Title27c1d143Ccdf419fAe929842beec9877 = "Resources";

// id: "e977adda-21b2-4711-bdb5-1166b519c984"
// title: "Login Page Title"
// type: :text
// key: "title"
// parent_id: "b8383ae8-75d3-4d25-b153-0fea0b1bec8f"
export const TitleE977adda21b24711Bdb51166b519c984 = "Login";

// id: "190410f2-d2a2-4c35-b7a8-18ee798e7424"
// title: "Secondary Marketing Header Reference"
// type: :reference
// key: "header"
// parent_id: nil
export function Header190410f2D2a24c35B7a818ee798e7424(props: any) {
  return (
    <SecondaryMarketingHeaderC9b608a9Cda54d6c8fa5Db800f84de9c parentTag="Header190410f2D2a24c35B7a818ee798e7424" {...props} />
  );
}

// id: "ce45e23d-a234-480e-bc0e-83d1e3d50a0f"
// title: ""
// type: :gf_preset
// key: "button"
// parent_id: "ede6772c-d4c8-42e4-adcd-7331f541f1c3"
export function ButtonCe45e23dA234480eBc0e83d1e3d50a0f(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Button parentTag={buildClassName("ButtonCe45e23dA234480eBc0e83d1e3d50a0f", parentTag)} href="/join" label="Join Us" {...props} />
  );
}

// id: "53c2b925-36f2-402f-a09b-a94bc3e6c4f2"
// title: ""
// type: :text
// key: "title"
// parent_id: "c9b608a9-cda5-4d6c-8fa5-db800f84de9c"
export const Title53c2b92536f2402fA09bA94bc3e6c4f2 = "Secondary Marketing Title 1";

// id: "cd509031-d957-4997-801c-b34179d022c0"
// title: ""
// type: :gf_preset
// key: "copyright"
// parent_id: "1e348db1-c323-4891-830d-01c29b55fc36"
export function CopyrightCd509031D9574997801cB34179d022c0(props: any) {
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupCopyright parentTag={buildClassName("CopyrightCd509031D9574997801cB34179d022c0", parentTag)} {...props} />
  );
}

// id: "d621eba0-8808-44e9-ad7a-c3f781eca322"
// title: ""
// type: :gf_preset
// key: "welcome-back"
// parent_id: "47bdaf1b-90c8-4b5c-98bf-c860b9e455ae"
export function WelcomeBackD621eba0880844e9Ad7aC3f781eca322(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <WelcomeBackPopUp parentTag={buildClassName("WelcomeBackD621eba0880844e9Ad7aC3f781eca322", parentTag)} {...props} />
  );
}

// id: "39159f95-5874-4927-b4b0-e481ab163359"
// title: "Home Page Body"
// type: :reference
// key: "body"
// parent_id: "47bdaf1b-90c8-4b5c-98bf-c860b9e455ae"
export function Body39159f9558744927B4b0E481ab163359(props: any) {
  return (
    <MarketingBody1e0dbd9dD36b46f6982b52a4399eaf84 parentTag="Body39159f9558744927B4b0E481ab163359" postSlug="home-page-posts2" {...props} />
  );
}

// id: "58d2cd96-c674-4e34-807d-5d4496120fd1"
// title: ""
// type: :html
// key: "logo"
// parent_id: "1e348db1-c323-4891-830d-01c29b55fc36"
export function Logo58d2cd96C6744e34807d5d4496120fd1(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.amazonaws.com/orgs/demo-2/logo-wide-vector.svg" alt="logo" className={`${parentTag || ""} Logo58d2cd96C6744e34807d5d4496120fd1 logo`} />
  );
}

// id: "d3aa9cf8-b728-4534-8c9d-38729eeb90d8"
// title: ""
// type: :reference
// key: "label"
// parent_id: "33c9dfd2-b441-4723-bdce-9aeb2eaf9e62"
export function LabelD3aa9cf8B72845348c9d38729eeb90d8(props: any) {
  return (
    <DefaultLogo52c87ec8B4c143b4885079fe82f5cdf5 parentTag="LabelD3aa9cf8B72845348c9d38729eeb90d8" {...props} />
  );
}

// id: "b96dd443-c212-4016-9cb0-15d510fee3b7"
// title: "Home Page postSlug"
// type: :text
// key: "postSlug"
// parent_id: "39159f95-5874-4927-b4b0-e481ab163359"
export const PostSlugB96dd443C21240169cb015d510fee3b7 = "home-page-posts2";

// id: "5ccd3ce6-5baa-431a-90ca-a31166208b3c"
// title: "Admin Pages"
// type: :html
// key: "admin-pages"
// parent_id: nil
export function AdminPages5ccd3ce65baa431a90caA31166208b3c(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AdminPages5ccd3ce65baa431a90caA31166208b3c`}>
      {props.children}
    </div>
  );
}

// id: "52c87ec8-b4c1-43b4-8850-79fe82f5cdf5"
// title: "Logo"
// type: :html
// key: "default-logo"
// parent_id: nil
export function DefaultLogo52c87ec8B4c143b4885079fe82f5cdf5(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.amazonaws.com/orgs/demo-2/logo-wide-vector.svg" className={`${parentTag || ""} DefaultLogo52c87ec8B4c143b4885079fe82f5cdf5 logo`} alt="logo" />
  );
}

const Components = {
  MembersAreaHeaderB98a193a0c9c4dbcA1c9Bb8cade3510d,
  ResourcesPage5428cbbf9acf44f9B7b8Fb037b60d85b,
  PostSlug0a1ef865376f416dA9a253a3fc4f0ef2,
  MarketingBody1e0dbd9dD36b46f6982b52a4399eaf84,
  PasswordResetPageBc6dea7eCcf24164Aee99484c90a4eab,
  Label59a8c267440e43cc9a16E405574163c5,
  MessagePages2dfae297Fc9c402aBa2aFed47f02733e,
  JoinUsPageE0015401C2d841b1989cFc92dda05889,
  FooterA6c22cdf3d7e45e69a247c2f1db0e5e6,
  MembersAreaDefault9455e651425647b6B5f7497e12561079,
  AboutUsPage693a5d677b29409c8c8cDb8c908f3b91,
  MarketingCardsC3ada224D6fe4378B5c895106ed3b3b2,
  SignupPagesEa38726d9a974b07B5e213f4d72abae7,
  MemberPagesCf7cbbf56b6f4163B2ebA11686a699b0,
  Header92578d29755d49b287d90f760cbd0660,
  DefaultHeader720afe3b47ca4fbb93ddA775f0583e30,
  HomePageHeroEde6772cD4c842e4Adcd7331f541f1c3,
  SecondaryPage9876ac9368a849929b915393e6e91d40,
  HeroD8f5ac419ff641508ee77992f959d315,
  ButtonClass8acd670fCb65454e890a52a75d4f39e3,
  HeroFe72742685d348d281b8Fe6608a50994,
  HeaderC648ca79Cda04f81998dD78f69980ad1,
  Groupflow68ffb37fA7754482B596A487c6a14ebe,
  Url5a3c4e274ab94789Aa5aEad7fee7de9f,
  NavMenuSlugFe3bf2c8F0bd465dA5c031eedd371a16,
  SecondaryMarketingHeaderC9b608a9Cda54d6c8fa5Db800f84de9c,
  PageQuote69fbadec03a4454aBf2a2afaedecb57a,
  FooterA13a9fd7C17a4245Bbfd0d9c864884f9,
  BackgroundImage595f20be1e534d82B291E43dbdba1979,
  SocialLinksD636e5718ecb4fa6Ae60Dcd910eed79e,
  LinkedLogo07c1dfd1Edab4c2b903eFcef0108f33e,
  Header5e83fbacDa144ef28a03129003878342,
  FooterA2ef0432786245b0885dC767e6862ebb,
  HrefF584a6ed7a954b05B6f5817842ea0c5f,
  Title2db740337e614686B9b82b11141bef23,
  Href1b85ba3aBd244a96Bf1fB44759bc9018,
  HomePage47bdaf1b90c84b5c98bfC860b9e455ae,
  FooterD13dfd186dc84f3486d98881cf39ab7d,
  HeaderTop4efd28f7F61f4ac7Ac8e5379eb47f5b5,
  SecondaryNavCf74f3e6Cadc466397e9A44c53ae2b15,
  Footer6aab30e006a14df883a7D7bdc1ecc952,
  Navigation3f19703493a84fa7B44eCe8cfe9524a6,
  NavMenuSlug0a4472b6B8474d418567Acb7f860cd3a,
  NavMenuSlug3422c893Bfbe40ba8beaF045970a741d,
  NavMenuSlugDe51cc6675b947bbAc980cd5b7196c68,
  LoginPageD757053e75114d40Aad0E45e1d75a5eb,
  SecondaryMarketingHero178537bd662d467e9c0d9823640bae4f,
  AltF0032d8d4b99455aB00fFc453e4c4d74,
  Header71753787976f4055843376fce7adee6d,
  FooterE052fbadC21c48d384704ece351ffce1,
  MarketingCardsA09a7637A052486d8cee36f1357cd568,
  Header19ca2622C68641faAc8eD4aeda379c63,
  LabelBebc07ccEb164674B5eb99b4e8e201fc,
  TitleD458c1f0A1f343eeBb171e3d59b8c9b8,
  PostSlug037573e818e14a2cA65c87aabbe352a6,
  PublicEventsPageF9b2afb3545e4592A545C9baa8ebbc99,
  HomeHeaderA3a72a3d17d245ae9eb14a4e5f00d193,
  Title505813efAe5c403685a3Cb82f9491ebb,
  HeaderTop7217c39101c347b1Babe8d180b35627f,
  Links490109df41214520A397D6e783744712,
  HeaderCa9243ca0cf24559B40482b106053663,
  Footer8af7926fAc6e49948305A48217b21445,
  Title9c8febf294704da3Af598b9c94a1c8ba,
  Label98f839b9Caa44d47B1dc0e25ee298b0e,
  NavMenuSlugCa40ec37E46d4546B7bc3a9f4fbc27d7,
  DefaultHeaderTop09bac254B4854ec3B6b35654aee06157,
  HeaderB8383ae875d34d25B1530fea0b1bec8f,
  QuoteAfd9abb3285c4e1aB287Bd6631a1d79f,
  NavMenuSlugF8bd24357eb8404dB267D8ee5a37d50a,
  Footer732b68952ebf496b862533d816cb26a1,
  SocialLinks275ba825529546c991b32632f39a6535,
  TitleAd14f008D0fa4e60B98e76aa1fff3d01,
  Title9a090ddd7ca34abdB6ee82c3fefb1517,
  Title095b7ce6E0c34ac393c62a019f304121,
  ShortHeaderB208e88eA1074e318c50Dcadc03155e2,
  LinkedLogo33c9dfd2B4414723Bdce9aeb2eaf9e62,
  SocialLinksE2067faeC27846779c71499871dea633,
  HeaderCbe1aef8E30c4987Afe29fd37f357dc5,
  Body367d50a0511a4bcb95dc4ac605946091,
  TitleF4677fb4F69f4a2f888827982a1e6077,
  Class42e28144E6884d0b8562C35a6ff9a841,
  ClassE09cc371B46f48b7Bebb01f90c680afe,
  DefaultFooter1e348db1C3234891830d01c29b55fc36,
  Navigation14b5bd0262bd438f8cb1C2325eedf53e,
  Header714da320A07c4f7dB95cBa5f4e0437fe,
  Header3a3a3e2bC0e84dc2B966B59f18568ff1,
  HeaderC76247ef30e74d47B3a2Dcc71d066984,
  HeaderTopEfd840d70f9a4775A03d8b2e9aba51d0,
  Header2bb3a118B2804e2a944e97bd9171f169,
  Title27c1d143Ccdf419fAe929842beec9877,
  TitleE977adda21b24711Bdb51166b519c984,
  Header190410f2D2a24c35B7a818ee798e7424,
  ButtonCe45e23dA234480eBc0e83d1e3d50a0f,
  Title53c2b92536f2402fA09bA94bc3e6c4f2,
  CopyrightCd509031D9574997801cB34179d022c0,
  WelcomeBackD621eba0880844e9Ad7aC3f781eca322,
  Body39159f9558744927B4b0E481ab163359,
  Logo58d2cd96C6744e34807d5d4496120fd1,
  LabelD3aa9cf8B72845348c9d38729eeb90d8,
  PostSlugB96dd443C21240169cb015d510fee3b7,
  AdminPages5ccd3ce65baa431a90caA31166208b3c,
  DefaultLogo52c87ec8B4c143b4885079fe82f5cdf5
}

export default Components;