const ROUTES = {
  "/": {
    "name": "HomePage47bdaf1b90c84b5c98bfC860b9e455ae",
    "type": "HTML",
    "key": "home-page"
  },
  "/_admin/*": {
    "name": "AdminPages5ccd3ce65baa431a90caA31166208b3c",
    "type": "HTML",
    "key": "admin-pages"
  },
  "/about": {
    "name": "AboutUsPage693a5d677b29409c8c8cDb8c908f3b91",
    "type": "HTML",
    "key": "about-us-page"
  },
  "/events/public": {
    "name": "PublicEventsPageF9b2afb3545e4592A545C9baa8ebbc99",
    "type": "HTML",
    "key": "public-events-page"
  },
  "/join": {
    "name": "JoinUsPageE0015401C2d841b1989cFc92dda05889",
    "type": "REFERENCE",
    "key": "join-us-page"
  },
  "/login": {
    "name": "LoginPageD757053e75114d40Aad0E45e1d75a5eb",
    "type": "HTML",
    "key": "login-page"
  },
  "/members/*": {
    "name": "MemberPagesCf7cbbf56b6f4163B2ebA11686a699b0",
    "type": "HTML",
    "key": "member-pages"
  },
  "/members/messages/*": {
    "name": "MessagePages2dfae297Fc9c402aBa2aFed47f02733e",
    "type": "HTML",
    "key": "message-pages"
  },
  "/reset_password": {
    "name": "PasswordResetPageBc6dea7eCcf24164Aee99484c90a4eab",
    "type": "REFERENCE",
    "key": "password-reset-page"
  },
  "/resources": {
    "name": "ResourcesPage5428cbbf9acf44f9B7b8Fb037b60d85b",
    "type": "REFERENCE",
    "key": "resources-page"
  },
  "/signup/*": {
    "name": "SignupPagesEa38726d9a974b07B5e213f4d72abae7",
    "type": "REFERENCE",
    "key": "signup-pages"
  }
};

export default ROUTES;